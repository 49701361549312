import React from 'react';
import Helmet from 'react-helmet';
import { HEIGHT, FAMILY, COLOR } from './vars';
import { mediaStyles } from "./breakpoints";
import { DEVICE } from './mediaquery';
import { Global, css } from '@emotion/react';
import Header from './Header/Header';
import CarouselHeader from './Header/CarouselHeader/CarouselHeader';
import Welcome from './Home/Welcome/Welcome';
import Sports from './Home/Sports/Sports';
import OnLine from './Home/OnLine/OnLine';
import Testimonials from './Home/Testimonials/Testimonials';
import Tarifas from './Home/Tarifas/Tarifas';
import About from './Home/About/About';
import LastBlogEntries from './Home/LastBlogEntries/LastBlogEntries';
import Footer from './Footer/Footer';
import Contact from './Contact/Contact';

const Layout = (props) => {
    
    return (
        <>
            <Global 
                styles={css`
                    
                    body {
                        height: 100%;
                        min-height: 100%;
                        font-family: ${FAMILY.secondary};
                        font-size: clamp(10px, 4vw, 18px);
                        color: ${COLOR.blackmedium};
                        line-height: clamp(23px, 3vw, 30px);
                        padding-top: ${HEIGHT.header} + 50px;
                        padding-top: ${HEIGHT.header} + 5vw;
                        background-color: ${COLOR.white};
                    }

                    h1, h2, h3, h4, h5 {
                        font-family: ${FAMILY.staatliches};
                    }

                    h1 {
                        font-size: clamp(2rem, 4vw, 4rem);
                        line-height: clamp(2rem, 4vw, 4rem);
                    }

                    h2 {
                        font-size: clamp(1.8rem, 3vw, 3.5rem);
                        line-height: clamp(2.0rem, 3vw, 3.5rem);
                    }

                    h1, h2 {
                        display: inline-block;
                        flex-direction: column;
                        color: white;
                        padding-top: clamp(7px, 5vw, 15px);
                        padding-bottom: clamp(7px, 5vw, 15px);
                        padding-left: 30px;
                        padding-right: 30px;
                        background-color: black;


                        @media ${DEVICE.desktop} { 
                            padding-left: clamp(12px, 5vw, 25px);
                            padding-right: clamp(12px, 5vw, 25px);
                        }
                    }

                    p {
                        margin-bottom: clamp(12px, 3vw, 16px);
                    }

                    .btn {
	                    width: fit-content;
                        font-family: ${FAMILY.staatliches};
                        font-size: clamp(16px, 1.5vw, 20px);
                        color: ${COLOR.tertiary};
                        text-align: center;
                        padding-top: clamp(5px, 1vw, 10px);
                        padding-bottom: clamp(5px, 1vw, 10px);
                        padding-left: clamp(10px, 3vw, 25px);
                        padding-right: clamp(10px, 3vw, 25px);
                        border: 0;
                        border-radius: 5px;
                        background-color: ${COLOR.secondary};
                        transition: all .35s;

                        &:hover {
                            color: ${COLOR.tertiary};
                            background-color: ${COLOR.primary} !important;
                        }
                    }
                `}
            />
            <Helmet>
                <meta name="theme-color" content="#000" />
                <title>Coach McTraining | Entrenamientos personalizados</title>
                <style>{mediaStyles}</style>
            </Helmet>
            
            <Header />
            <CarouselHeader />
            <Welcome />
            <Sports />
            <OnLine />
            <Testimonials />
            <Tarifas />
            {/* <LastBlogEntries /> */}
            <About />
            <Footer />
            <Contact />

            {props.children}

        </>
    );
}
 
export default Layout